import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/LayoutView'
import PropertyListing from '../components/PropertyListing'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

  const PropertyCategoryTypeByCity = ({ data, pageContext }) => {
  const { propertyInfo, propertyType, categoryInfo, properties } = data
  const title = `${propertyType.name} ${categoryInfo.name} in ${propertyInfo.name}`
  const description = `Check out our listings of ${propertyType.name} ${categoryInfo.name} in ${propertyInfo.name}`

  return (
    <Layout>
       <GatsbySeo
      title={title}
      description={description}
      openGraph={{
        title: {title},
        description: {description},
        url: 'https://www.example.com/articles/article-title',
        images: 
          {
            url: 'https://images.ctfassets.net/abyiu1tn7a0f/131ePdA3lUnISWi6UzYfq9/ef10c36ff7cef5ec33baa54fc809c167/apartments-and-houses-for-sale-ikate.jpg?h=250',
            width: 850,
            height: 650,
            alt: 'Og Image Alt Article Title A',
          },
        
        site_name: 'Owning a home does not need to make you lose sleep',
      }}
      twitter={{
        handle: '@ownahome_ng',
        site: '@ownahome.ng',
        cardType: 'summary_large_image',
      }}
    />
      
      <div className='pt-5 pd-bottom-70'>
        <div className='row mb-4'>
          <div className='col-9 offset-md-3'>
          <h1 className=' text-center h4 my-4'>{title}</h1>
            <div className='d-flex justify-content-center align-items-center flex-wrap'>
              {propertyInfo.locality_in_city.map((node, index) => {
                return (
                  <h2 className='px-2 text-muted h6 border-end title readeal-top' key={index}>
                    <Link
                      to={`/property/${categoryInfo.slug}/${propertyType.slug}/${propertyInfo.slug}/${node.slug}`}
                    >
                      {node.name}
                    </Link>
                  </h2>
                )
              })}
            </div>
          </div>
        </div>
        <PropertyListing
          properties={properties}
          pageContext={pageContext}
          pathname={`/property/${categoryInfo.slug}/${propertyType.slug}`}
        />
      </div>
    </Layout>
  )
}

export default PropertyCategoryTypeByCity

// Not done with the query string.
export const query = graphql`
  query allPropertyTypeByCityQuery(
    $slug: String!
    $type: String!
    $category: String!
  ) {
    propertyInfo: contentfulPropertyCategoryByCity(slug: { eq: $slug }) {
      id
      name
      slug
      description {
        description
      }
      locality_in_city {
        name
        slug
      }
    }

    propertyType: contentfulPropertyCategoryByType(slug: { eq: $type }) {
      name
      slug
      id
    }

    categoryInfo: contentfulPropertyCategory(slug: { eq: $category }) {
      id
      slug
      name
    }

    properties: allContentfulProperty(
      filter: {
        propertyType: { slug: { eq: $type } }
        propertyCategory: { slug: { eq: $category } }
        propertyCategoryByCity: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...Property
      }
      ...PropertyPagination
    }
  }
`
